.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start; 
  padding: 50px;
  gap: 20px; 
}

.card {
  width: 100%;
  max-width: 30rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin: 30vh 20px;
  text-align: center;
  box-shadow: 0 5px 8px rgba(0,0,0,0.1);
}

.card-img {
  width: 70%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 4px;
}

.card-button {
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  background-color: #1B1717;
  color: #EEEBDD;
  cursor: pointer;
  font-size: 1.5rem; 
}

p {
  font-size: 1.2rem; 
  font-weight: 400;
  margin-bottom: 20px;
}
