.nav-bar {
  background: #1b1717;
  width: 90px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;
    width: 90px;
    height: 90px;

    img {
      display: block;
      margin-left: auto;
      width: 100%;
      height: auto;
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #1b1717;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #eeebdd;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: "";
        font-size: 14px;
        letter-spacing: 2px;
        font-weight: 500;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: "HOME";
        }
      }
    }

    a.about-link {
      &:after {
        content: "ABOUT";
      }
    }

    a.contact-link {
      &:after {
        content: "CONTACT";
      }
    }

    a.portfolio-link {
      &:after {
        content: "PORTFOLIO";
      }
    }
    a.projects-link {
      &:after {
        content: "PROJECTS";
      }
    }

    a.active {
      svg {
        color: #eeebdd;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 20px;
        line-height: 26px;

        &:hover svg {
          color: #eeebdd;
        }
      }
    }
  }
}
.hamburger-icon,
.close-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  body {
    overflow-y: hidden;
  }
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;

    ul,
    nav {
      display: none;
    }
    nav {
      width: 100%;
      height: 10%;
      background: #1b1717;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 3;
      margin: 0;

      .icons {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 28px;

        a {
          font-size: 25px;
          opacity: 1;
          margin: auto 10px;
          padding: 0px 5px;
          &:after{
            margin-left: -5px;
          }
        } 
     
      }

      &.mobile-show {
        display: flex;
        justify-content: left;

        .icons {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .hamburger-icon,
    .close-icon {
      display: block;
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }
}
