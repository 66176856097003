.logo-container {
  z-index: 0;
  width: 800px;
  height: 809px;
  opacity: 1;
  position: absolute;
  top: -5%;
  right: -25%;
  bottom: 0;
  left: 20%;
  margin: auto;

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 1;
    z-index: 1;
    animation: fadeIn 8s;
  }
}

@media screen and (max-width: 1200px) {

  .logo-container, .solid-logo{
    max-width: 390px;
    max-height: 390px;
  }
          
}

/*.logo-container {
  z-index: 0;
  width: 800px;
  height: 809px;
  opacity: 0;
  position: absolute;
  top: -5%;
  right: -25%;
  bottom: 0;
  left: 20%;
  margin: auto;
  
  svg {
    width: 100%;
    height: auto;
    bottom: 0;  
    position: relative;
    top: 4%;
    left: 3%;
  }

  .solid-logo{
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity: 0;
      z-index: 1;
      
      
  }

} */
