.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  h1 {
    color: #810000;
    font-size: 53px;
    margin: 0;
    font-family: "Coolvetica";
    font-weight: 400;
    animation: fadeIn 1s;

    &::before {
      content: "<h1>";
      font-family: "La Belle Aurore";
      color: #630000;
      font-size: 25px;
      position: absolute;
      margin-top: -40px;
      left: 15px;
      animation: fadeIn 1s backwards;
    }

    &::after {
      content: "</h1>";
      font-family: "La Belle Aurore";
      color: #810000;
      font-size: 25px;
      position: absolute;
      margin-top: 90px;
      left: 15px;
      animation: fadeIn 1s backwards;
    }
  }

  h2 {
    color: #1b1717;
    margin-top: 70px;
    font-weight: 400;
    font-size: 20px;
    font-family: sans-serif;
    letter-spacing: 2.5px;
    animation: fadeIn 1s 1.5s backwards;
  }

  .flat-button {
    color: #810000;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #810000;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 2.5s backwards;
    white-space: nowrap;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #630000;
      color: #fff;
    }
  }
}

@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1::before,
  .home-page h1::after {
    display: none;
  }
  
    .home-page {
      .text-zone {
        position: initial;
        width: 100%;
        max-width: 350px;
        margin-left: 15px;
        margin-top: 40px;
        transform: none;
        padding: none;
        box-sizing: border-box;
        h1 {
          font-size: 40px;
        }
        h2 {
          margin-top: 50px;
          font-size: 18px;
        }

        .flat-button{
          margin-top: 50px;
        }

      }
      .logo-container {
        display: none;
      }

      
    }
  }

